<template>
  <div class="bossWaybillManagePage">
    <!-- 第一步 -->
    <StepOne v-if="currentStep == 1" @stepChange="stepChange" @setWaybillList="setWaybillList"></StepOne>
    <!-- 第二步 -->
    <StepTwo v-if="currentStep == 2" @stepChange="stepChange" :waybillList="waybillList" :ImportBatchID="ImportBatchID"></StepTwo>
    <!-- 第三步 -->
    <StepThree v-if="currentStep == 3" @stepChange="stepChange" @setWaybillList="setWaybillList"></StepThree>
    <!-- 第四步 -->
    <StepFour v-if="currentStep == 4" @stepChange="stepChange"></StepFour>
  </div>
</template>

<script>
import StepOne from './cmpt/stepOne'
import StepTwo from './cmpt/stepTwo'
import StepThree from './cmpt/stepThree'
import StepFour from './cmpt/stepFour'

export default {
  data() {
    return {
      currentStep: null, //当前处于第几步
      waybillList: [], //上传的运单列表
      ImportBatchID: '', // 待结算单号
    }
  },
  methods: {
    //改变步骤
    stepChange(e) {
      this.currentStep = e
    },
    //获取运单列表
    setWaybillList(e) {
      this.waybillList = e
    }
  },
  created() {
    if (this.$route.query.step) {
      this.currentStep = this.$route.query.step
    }
    if (this.$route.query.ImportBatchID) {
      this.ImportBatchID = this.$route.query.ImportBatchID
    }
  },
  computed: {
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour
  }
}
</script>

<style lang="scss" scoped>
</style>